import React from "react";
import routes from "./routes";
import { Route, Redirect, Switch, HashRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

const customHistory = createBrowserHistory();

const RouteWithSubRoutes = ({ path, routes, component }) => {
  const Component = component;
  return (
    <Route
      path={path}
      render={props => <Component exact {...props} routes={routes} />}
    />
  );
};

const Container = props => {
  return <div>{props.children}</div>;
};

export default () => {
  return (
    <HashRouter history={customHistory}>
      <Container>
        <Switch>
          {routes.map((route, i) => {
            return <RouteWithSubRoutes key={i} {...route} />;
          })}
          <Redirect to="/index" />
        </Switch>
      </Container>
    </HashRouter >
  );
};
