import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Header from "../../component/header";
import MoHeader from "../../component/header/moHeader";
import Footer from "../../component/footer";
import style from "./index.less";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "../../store/actions";

function PcRender(props) {
  return (
    <div className={`${style.body_wrap}`}>
      <Header active="characteristic" />
      <div className={`${style.body_banner}`}>
        <div className={`${style.body_img}`}>
          <img src="https://img01.fxqifu.com/98635322d27e472321140df6efe1642e?x-oss-process=image/quality,q_80" alt="" />
        </div>
      </div>
      <div className={`${style.body_content}`}>
        <div className={`${style.pc_sass}`}>
          <div className={`${style.tit}`}>象加SAAS系统</div>
          <div className={`${style.line}`} />
          <div className={`${style.remark}`}>HR只需要从象加PC登录进象加管理后台即可。在PC端管理后台，HR可以管理员工、发放积分、购买福利解决方案，给员工发放福利或者积分等等所有操作。</div>
          <div className={`${style.img}`}>
            <img src="https://img01.fxqifu.com/1524be6762092d364e9c34065bdacd9c?x-oss-process=image/quality,q_80" alt="" />
          </div>
        </div>
        <div className={`${style.ad_text}`}>
          <div className={`${style.tit}`}>象加福利卡，多种选择</div>
          <div className={`${style.remark}`}>会员制员工福利解决方案</div>
        </div>
        <div className={`${style.pc_cards}`}>
          <div className={`${style.content}`}>
            <div className={`${style.img}`}>
              <img src="https://img01.fxqifu.com/cb86e2eea96d82f3d0c3483d7584baf6?x-oss-process=image/quality,q_80" alt="" />
            </div>
            <div className={`${style.cont}`}>
              <div className={`${style.remark}`}>
                <div className={`${style.icon} ${style.icon1}`} />
                <div className={`${style.text}`}>每种卡除了基本的会员折扣权益，还包含价值不等的实物权益，员工可选择性的领取实物或者相应的积分。</div>
              </div>
              <div className={`${style.remark}`}>
                <div className={`${style.icon} ${style.icon2}`} />
                <div className={`${style.text}`}>系统自动定时提醒员工领取节日/生日福利，hr无需操心。同时每种卡均包含一年内的象加福利平台全场9.5折购物权益。</div>
              </div>
              <div className={`${style.remark}`}>
                <div className={`${style.icon} ${style.icon3}`} />
                <div className={`${style.text}`}>员工离职，会员卡的9.5折权益员工可以继续享有之外，其他节日/生日权益全部以等值积分的形式退回到公司的账户。</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${style.ad_text}`}>
          <div className={`${style.tit}`}>象加体系，多种产品</div>
          <div className={`${style.remark}`}>商城&nbsp;&nbsp;&nbsp;&nbsp;赞赏</div>
        </div>
        <div className={`${style.goods}`}>
          <div className={`${style.goods_cont}`}>
            <div className={`${style.remark}`}>
              <div className={`${style.tit}`}>海量商品，积分团兑</div>
              <div className={`${style.line}`} />
              <div className={`${style.cont}`}>积分商城，数百种爆款商品推荐、全品类覆盖，总有一款适合你；可以用积分兑换，也可以用现金直购，更有拼团、秒杀等购物方式为你省钱。</div>
            </div>
            <div style={{ marginTop: "39px" }} className={`${style.img}`}>
              <img src="https://img01.fxqifu.com/7cd81afc290ddadc941e4b4b7aa46ae6?x-oss-process=image/quality,q_80" alt="" />
            </div>
          </div>
        </div>
        <div className={`${style.goods}`}>
          <div className={`${style.goods_cont}`}>
            <div style={{ marginTop: "100px" }} className={`${style.img}`}>
              <img src="https://img01.fxqifu.com/dd37b708e9f6fcb3883e594de0e06875?x-oss-process=image/quality,q_80" alt="" />
            </div>
            <div className={`${style.remark}`}>
              <div className={`${style.tit}`}>小赞赏，大激励</div>
              <div className={`${style.line}`} />
              <div className={`${style.cont}`}>企业可自由创建企业专属激励币，自主约定价值，发放给员工，通过员工互赞流通起来，实现员工之间自我驱动。</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
function MobileRender() {
  return (
    <div>
      <MoHeader />
      <div className={`${style.mo_banner}`}>让工作更加温暖</div>
      <div className={`${style.sass}`}>
        <div className={`${style.tit}`}>象加SAAS系统</div>
        <div className={`${style.line}`} />
        <div className={`${style.remark}`}>HR只需要从象加PC登录进象加管理后台即可。在PC端管理后台，HR可以管理员工、发放积分、购买福利解决方案，给员工发放福利或者积分等等所有操作。</div>
        <div className={`${style.img} ${style.img1}`} />
      </div>
      <div className={`${style.mo_btext}`}>
        <div className={`${style.tit}`}>象加福利卡，多种选择</div>
        <div className={`${style.remark}`}>会员制员工福利解决方案</div>
      </div>
      <div className={`${style.cards}`}>
        <div className={`${style.img}`} />
        <div className={`${style.cont}`}>
          <div className={`${style.desc}`}>
            <div className={`${style.icon} ${style.icon1}`} />
            <div className={`${style.remark}`}>每种卡除了基本的会员折扣权益，还包含价值不等的实物权益，员工可选择性的领取实物或者相应的积分。</div>
          </div>
          <div className={`${style.desc}`}>
            <div className={`${style.icon} ${style.icon2}`} />
            <div className={`${style.remark}`}>系统自动定时提醒员工领取节日/生日福利，hr无需操心。同时每种卡均包含一年内的象加福利平台全场9.5折购物权益。</div>
          </div>
          <div className={`${style.desc}`}>
            <div className={`${style.icon} ${style.icon3}`} />
            <div className={`${style.remark}`}>员工离职，会员卡的9.5折权益员工可以继续享有之外，其他节日/生日权益全部以等值积分的形式退回到公司的账户。</div>
          </div>
        </div>
      </div>
      <div className={`${style.mo_btext}`}>
        <div className={`${style.tit}`}>象加体系，多种产品</div>
        <div className={`${style.remark}`}>商城&nbsp;&nbsp;&nbsp;&nbsp;赞赏</div>
      </div>
      <div className={`${style.sass}`}>
        <div className={`${style.tit}`}>海量商品，积分团兑</div>
        <div className={`${style.line}`} />
        <div className={`${style.remark}`}>积分商城，数百种爆款商品推荐、全品类覆盖，总有一款适合你；可以用积分兑换，也可以用现金直购，更有拼团、秒杀等购物方式为你省钱。</div>
        <div className={`${style.img} ${style.img2}`} />
      </div>
      <div className={`${style.sass}`} style={{ paddingTop: 0 }}>
        <div className={`${style.tit}`}>小赞赏，大激励</div>
        <div className={`${style.line}`} />
        <div className={`${style.remark}`}>企业可自由创建企业专属激励币，自主约定价值，发给员工，通过员工互赞流通，实现员工之间自我驱动。</div>
        <div className={`${style.img} ${style.img3}`} />
      </div>
      {/* <div className={` ${style.bottom}`}>
        <div className={` ${style.mo_qcode}`} />
        <div className={` ${style.bot_text}`}>扫码添加公众号</div>
        <div className={` ${style.bot_remark}`}>电话热线 / 商业合作:400-160-1160</div>
      </div> */}
    </div>
  );
}
@withRouter
class Index extends Component {
  constructor() {
    super();
    this.state = {
      visible: false
      // isMobile: false
    };
  }
  componentWillMount() {
    // 判断移动端
    if ("ontouchstart" in window) {
      this.props.isMobile(true);
    }
  }
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  render() {
    if (this.props.isMobileFlag) {
      return <MobileRender />;
    } else {
      return (
        <div>
          <PcRender visible={this.state.visible} onCancel={this.handleCancel} onClick={this.showModal} />
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    isMobileFlag: state.judgeDevice
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ isMobile }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
