var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3cT0JCzcPs07bWMCCXjUeJ {\n  *zoom: 1;\n  *clear: both;\n}\n._3cT0JCzcPs07bWMCCXjUeJ:before,\n._3cT0JCzcPs07bWMCCXjUeJ:after {\n  display: table;\n  clear: both;\n  content: '';\n}\n._23dBygYZAnzoh-3dzbxYrX {\n  float: left;\n}\n._2twJsSs8QJ3FBF5_OW-VXw {\n  float: right;\n}\n.ant-modal-close-icon {\n  color: #aaa666;\n  font-size: 15px;\n  font-weight: bold;\n}\n.ant-modal-close-x {\n  width: 79px;\n  height: 79px;\n  line-height: 79px;\n}\n._1wJFC3n3seQhrT5EaW0gGy ._2IaAsSvtb-3HKp-Qd2DCwx {\n  width: 100%;\n  height: 520px;\n  left: 0;\n  top: 0;\n}\n._1wJFC3n3seQhrT5EaW0gGy ._2IaAsSvtb-3HKp-Qd2DCwx ._250RHaj5GhKuWxrDmVRd9Y {\n  position: relative;\n  width: 100%;\n  height: 520px;\n  overflow: hidden;\n}\n._1wJFC3n3seQhrT5EaW0gGy ._2IaAsSvtb-3HKp-Qd2DCwx ._250RHaj5GhKuWxrDmVRd9Y img {\n  position: absolute;\n  top: 47%;\n  transform: translate(0, -50%);\n  width: 100%;\n}\n._2g_GJ6pzgJumVPJOU_Xyhr {\n  z-index: 2;\n  background: #fff;\n}\n._2g_GJ6pzgJumVPJOU_Xyhr ._2BbGP5RaiTEq4z4VjCu5Qz {\n  width: 100%;\n  height: 550px;\n  background: #fafafa;\n}\n._2g_GJ6pzgJumVPJOU_Xyhr ._2BbGP5RaiTEq4z4VjCu5Qz .o176iadhwpX0WE5n1IwYk {\n  width: 938px;\n  height: 550px;\n  padding-top: 60px;\n  margin: 0 auto;\n  font-size: 14px;\n  font-weight: 500;\n  color: #4a4a4a;\n  line-height: 30px;\n}\n.Od2Md3RCgkVLT7XET-g4z {\n  width: 100%;\n  height: 3.2rem;\n  background: url(" + escape(require("../../assets/images/aboutus.png")) + ") no-repeat;\n  background-size: 100% 100%;\n}\n._12Shjhf7kdw-tb3lpif2yp {\n  padding: 0.7rem;\n  font-size: 0.28rem;\n  font-weight: 500;\n  color: #4a4a4a;\n  line-height: 0.6rem;\n}\n", ""]);

// exports
exports.locals = {
	"fix": "_3cT0JCzcPs07bWMCCXjUeJ",
	"l": "_23dBygYZAnzoh-3dzbxYrX",
	"r": "_2twJsSs8QJ3FBF5_OW-VXw",
	"body_wrap": "_1wJFC3n3seQhrT5EaW0gGy",
	"body_banner": "_2IaAsSvtb-3HKp-Qd2DCwx",
	"body_img": "_250RHaj5GhKuWxrDmVRd9Y",
	"body_content": "_2g_GJ6pzgJumVPJOU_Xyhr",
	"about": "_2BbGP5RaiTEq4z4VjCu5Qz",
	"cont": "o176iadhwpX0WE5n1IwYk",
	"mo_banner": "Od2Md3RCgkVLT7XET-g4z",
	"mo_text": "_12Shjhf7kdw-tb3lpif2yp"
};