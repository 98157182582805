import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Header from "../../component/header";
import MoHeader from "../../component/header/moHeader";
import Footer from "../../component/footer";

import style from "./index.less";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "../../store/actions";

function PcRender(props) {
  return (
    <div className={`${style.body_wrap}`}>
      <Header active="aboutUs" fixed="false" />
      <div className={`${style.body_banner}`}>
        <div className={`${style.body_img}`}>
          <img src="https://img01.fxqifu.com/47bd1df1e0da67e4e68994e15a223814?x-oss-process=image/quality,q_80" alt="" />
        </div>
      </div>
      <div className={`${style.body_content}`}>
        <div className={`${style.about}`}>
          <div className={`${style.cont}`}>
            杭州象加网络技术有限公司是一个致力于员工激励，关注员工职场成就感，挖掘员工潜能的 团队。持续致力于提升员工参与意识，促进公司企业文化建设，聚焦员工利益，激发员工 幸福感和归宿感是象加的产品使命。
            <br /> 我们为公司提供员工激励产品和解决方案，帮助企业落地企业文化建设，让企业管理更省心。
            <br />
            <br />
            我们相信：Better work,better life.
            <br />
            <br />
            联系我们
            <br />
            商务合作：0571-81389117&nbsp;&nbsp;&nbsp;&nbsp;400-160-1160
            <br />
            市场合作：yangdd@fxqifu.com
            <br />
            地址：杭州市西湖区西斗门路3号天堂软件园A幢7F
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
function MobileRender() {
  return (
    <div>
      <MoHeader />
      <div className={`${style.mo_banner}`} />
      <div className={`${style.mo_text}`}>
        杭州象加网络技术有限公司是一个致力于员工激励，关注员工职场成就感，挖掘员工潜能的 团队。持续致力于提升员工参与意识，促进公司企业文化建设，聚焦员工利益，激发员工 幸福感和归宿感是象加的产品使命。
        <br /> 我们为公司提供员工激励产品和解决方案，帮助企业落地企业文化建设，让企业管理更省心。
        <br />
        <br />
        我们相信：Better work，better life.
        <br />
        <br />
        联系我们
        <br />
        商务合作：0571-81389117&nbsp;&nbsp;&nbsp;&nbsp;400-160-1160
        <br />
        市场合作：yangdd@fxqifu.com
        <br />
        地址：杭州市西湖区西斗门路3号天堂软件园A幢7F
      </div>
    </div>
  );
}
@withRouter
class Index extends Component {
  constructor() {
    super();
    this.state = {
      visible: false
      // isMobile: false
    };
  }
  componentWillMount() {
    // 判断移动端
    if ("ontouchstart" in window) {
      this.props.isMobile(true);
    }
  }
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  render() {
    if (this.props.isMobileFlag) {
      return <MobileRender />;
    } else {
      return (
        <div>
          <PcRender visible={this.state.visible} onCancel={this.handleCancel} onClick={this.showModal} />
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    isMobileFlag: state.judgeDevice
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ isMobile }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
