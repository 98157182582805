import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import style from "./index.less";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "../../store/actions";
import { assets_base_url } from "../../request/baseUrl";

function PcRender(props) {
  return <div />;
}
function MobileRender() {
  return (
    <div>
      <iframe title="secrets" className={`${style.text}`} height="100%" src={assets_base_url[process.env.NODE_ENV] + "secrets.html"} width="100%" frameBorder="0" />
    </div>
  );
}
@withRouter
class Index extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentWillMount() {
    // 判断移动端
    if ("ontouchstart" in window) {
      this.props.isMobile(true);
    }
  }

  render() {
    if (this.props.isMobileFlag) {
      return <MobileRender />;
    } else {
      return (
        <div>
          <PcRender />
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    isMobileFlag: state.judgeDevice
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ isMobile }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
