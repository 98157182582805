import { combineReducers } from "redux";
const judgeDevice = (state = false, action) => {
  switch (action.type) {
    case "JUDGE_DEVICE":
      return action.result;
    default:
      return state;
  }
};
// 活动相关信息
const activeCont = {
  h5_url: null,
  main_pic: null,
  name: null,
  state: "2",
  close: true
};
const judgeActive = (state = activeCont, action) => {
  switch (action.type) {
    case "JUDGE_ACTIVE":
      const prevState = { ...state };
      return Object.assign(prevState, action.result);
    default:
      return state;
  }
};
export default combineReducers({
  judgeDevice,
  judgeActive
});
