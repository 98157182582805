import Index from "../page/Index";
import Characteristic from "../page/Characteristic";
import Aboutus from "../page/Aboutus";
// 移动端专有页面-象加服务协议
import Agree from "../page/Agree/agrees";
// 移动端专有页面-象加服务隐私政策
import Secret from "../page/Agree/secrets";

export default [
  {
    path: "/index",
    component: Index
  },
  {
    path: "/characteristic",
    component: Characteristic
  },
  {
    path: "/aboutUs",
    component: Aboutus
  },
  // 移动端专有页面-象加服务协议
  {
    path: "/agrees",
    component: Agree
  },
  // 移动端专有页面-象加服务隐私政策
  {
    path: "/secrets",
    component: Secret
  }
];
