import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import style from "./index.less";
import { login_base_url } from "../../request/baseUrl";
import { connect } from "react-redux";
@withRouter
class Index extends Component {
  constructor() {
    super();
    this.state = {
      fixed: true,
      active: null,
      share_id: null
    };
  }
  componentWillMount = () => {
    // 分享参数
    const share_id = this.getUrlParam('share_id');
    // 是否fixed定位
    const { fixed = true, active = null } = this.props;
    this.setState({
      fixed: fixed === "false" ? false : true,
      active: active,
      share_id: share_id
    });
  };
  getUrlParam = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) { return decodeURIComponent(r[2]); }
    return null; //返回参数值
  }
  goCharacteristic = () => {
    this.props.history.push("/characteristic");
  };
  goIndex = () => {
    this.props.history.push("/index");
  };
  goAboutUs = () => {
    this.props.history.push("/aboutUs");
  };
  render() {
    return (
      <header>
        <div className={`${style.header} ${style.fix} ${this.state.fixed ? style.fixed : style.absolute}`}>
          <div className={`${style.l} ${style.logo}`} />
          <a href={login_base_url[process.env.NODE_ENV] + "login"} className={`${style.r} ${style.header_btn}`}>
            客户登录
          </a>
          <a href={login_base_url[process.env.NODE_ENV] + "register?share_id=" + this.state.share_id} className={`${style.r} ${style.header_btn} ${style.register} ${style.margin_btn}`}>
            注册象加
            <div className={`${style.tips} ${this.props.activeCont.name ? "" : style.hide}`}>{this.props.activeCont.name}</div>
          </a>
          <a onClick={this.goAboutUs} href="javascript:;" className={`${style.r} ${style.margin_href} ${this.state.active === "aboutUs" ? style.active : ""}`}>
            关于我们
          </a>
          <a onClick={this.goCharacteristic} href="javascript:;" className={`${style.r} ${style.margin_href} ${this.state.active === "characteristic" ? style.active : ""}`}>
            产品特点
          </a>
          <a onClick={this.goIndex} href="javascript:;" className={`${style.r} ${style.margin_href} ${this.state.active === "index" ? style.active : ""}`}>
            首页
          </a>
        </div>
      </header>
    );
  }
}
const mapStateToProps = state => {
  return {
    activeCont: state.judgeActive
  };
};
export default connect(mapStateToProps)(Index);
