// 调用接口的引用
const origin = window.location.origin
export const api_base_url = {
  development: "/mock",
  // development: "http://welfare.fxqifu.com.cn",
  test: 'http://welfare.fxqifu.com.cn',
  production: 'https://welfare.fxqifu.com'
};
// 登录的跳转引用
export const login_base_url = {
  development: `${origin}/xjia-admin-pc/#/`,
  test: `${origin}/xjia-admin-pc/#/`,
  production: `${origin}/xjia-admin-pc/#/`
};
// 静态文件的引用
export const assets_base_url = {
  development: "./",
  test: `${origin}/xjia-website/dist/`,
  production: `${origin}/xjia-website/dist/`
};
