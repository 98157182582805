import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import style from "./index.less";

@withRouter
class Index extends Component {
  constructor() {
    super();
    this.state = {
      opened: false
    };
  }
  componentWillMount = () => {};
  goCharacteristic = () => {
    this.props.history.push("/characteristic");
  };
  goIndex = () => {
    this.props.history.push("/index");
  };
  goAboutUs = () => {
    this.props.history.push("/aboutUs");
  };
  close = () => {
    this.setState({
      opened: false
    });
  };
  open = () => {
    this.setState({
      opened: true
    });
  };
  render() {
    return (
      <header className={`${style.mo_header}`}>
        <div className={`${style.mo_menuBtn}`} onClick={this.open} />
        <div className={`${style.mo_listBtn} ${this.state.opened ? style.opened : ""}`}>
          <div className={`${style.fix} ${style.mo_close}`}>
            <i className={`${style.r} ${style.icon}`} onClick={this.close} />
          </div>
          <div onClick={this.goIndex} className={`${style.mo_list}`}>
            官网首页
          </div>
          <div onClick={this.goCharacteristic} className={`${style.mo_list}`}>
            产品与服务
          </div>
          <div onClick={this.goAboutUs} className={`${style.mo_list}`}>
            关于我们
          </div>
        </div>
      </header>
    );
  }
}
export default Index;
