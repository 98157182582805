import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import style from "./index.less";

@withRouter
class Index extends Component {
  render() {
    return (
      <footer>
        <div className={style.footer}>
          <div className={style.bg} />
        </div>
        <div className={style.icp}>All Right Reserved <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备2021009972号-1</a></div>
      </footer>
    );
  }
}
export default Index;
