var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "footer {\n  position: relative;\n  z-index: 2;\n  width: 100%;\n  background: #F8F8F8;\n}\n._37kvPkYW-qGadAcTk8bp2_ {\n  width: 100%;\n  height: 270px;\n  margin: 0 auto;\n  background: #F8F8F8;\n}\n._3zsLEyrOZo7c7ON4d26ZZT {\n  width: 1440px;\n  height: 270px;\n  margin: 0 auto;\n  background: url(" + escape(require("../../assets/images/footer_02.png")) + ") center center no-repeat;\n}\n._1wh7FW0ZEGIUsBA6m4X50h {\n  text-align: center;\n  font-size: 14px;\n  font-weight: 400;\n  color: #4a4a4a;\n  line-height: 60px;\n}\n", ""]);

// exports
exports.locals = {
	"footer": "_37kvPkYW-qGadAcTk8bp2_",
	"bg": "_3zsLEyrOZo7c7ON4d26ZZT",
	"icp": "_1wh7FW0ZEGIUsBA6m4X50h"
};