import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import style from "./index.less";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile, isActive } from "../../store/actions";

function PcRender(props) {
  return (
    <div className={`${style.pc_mask} ${props.active.close ? style.hide : ""}`}>
      <div className={`${style.pc_model}`}>
        <img src={props.active.main_pic} alt="" />
        <div onClick={props.onClick} className={`${style.pc_close}`} />
      </div>
    </div>
  );
}
function MobileRender(props) {
  return (
    <div>
      <div className={`${style.mo_mask} ${props.active.close ? style.hide : ""}`} />
      <div className={`${style.mo_model} ${props.active.close ? style.hide : ""}`}>
        <img src={props.active.h5_url} alt="" />
        <div onClick={props.onClick} className={`${style.mo_close}`} />
      </div>
    </div>
  );
}
@withRouter
class Index extends Component {
  constructor() {
    super();
  }
  async componentWillMount() {
    // 判断移动端
    if ("ontouchstart" in window) {
      this.props.isMobile(true);
    }
  }
  close() {
    this.props.isActive({ close: true });
  }
  render() {
    if (this.props.isMobileFlag) {
      return <MobileRender onClick={() => this.close()} active={this.props.activeCont} />;
    } else {
      return <PcRender onClick={() => this.close()} active={this.props.activeCont} />;
    }
  }
}
const mapStateToProps = state => {
  return {
    isMobileFlag: state.judgeDevice,
    activeCont: state.judgeActive
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ isMobile, isActive }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
