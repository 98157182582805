import request from "./index";

// 发送注册验证码
export function sendRegisterCode(data) {
  return request({
    url: "/company/send-register-code",
    method: "post",
    data
  });
}
// 企业注册
export function companyUserRegister(data) {
  return request({
    url: "/company/user-register",
    method: "post",
    data
  });
}
// 企业创建
export function companyCreate(data) {
  return request({
    url: "/company/create",
    method: "post",
    data
  });
}
// 官网注册页-弹窗
export function registerHomeActivity(data) {
  return request({
    url: "/company/operating/register-home-activity",
    method: "get",
    data
  });
}
