import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import style from "./index.less";
import { Toast } from "antd-mobile";
import "antd-mobile/lib/toast/style/css";
import Cookies from "js-cookie";
import { sendRegisterCode, companyUserRegister, companyCreate } from "../../request/api";
@withRouter
class Index extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      times: 60,
      send: false,
      RealPhone: null,
      test: "",
      list: [],
      customer_nature: null,
      customer_name: null,
      first: true,
      second: false,
      third: false,
      // 倒计时
      count: null,
      created: null,
      share_id: null,
      registerLoading: false
    };
  }
  componentWillMount = async () => {
    // 分享参数
    const share_id = this.getUrlParam('share_id');
    this.setState({
      share_id: share_id
    })
  };
  getUrlParam = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) { return decodeURIComponent(r[2]); }
    return null; //返回参数值
  }
  goAgree = () => {
    this.props.history.push("/agrees");
  };
  goSecret = () => {
    this.props.history.push("/secrets");
  };
  getPhone(event) {
    this.setState({ phone: event.target.value.trim() });
  }
  async getTest() {
    if (this.state.send) return;
    const { phone } = this.state;
    const phoneReg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
    if (!phoneReg.test(phone)) {
      Toast.info("请输入正确手机号码", 1.5);
      return;
    }
    const data = await sendRegisterCode({
      phone: phone
    });
    Toast.info(data.msg, 1.5);
    // 记录发送验证码的手机号 防止发送完被改的情况
    this.setState({ RealPhone: phone });
    // 开始倒计时
    this.count(0);
  }
  // 倒计时
  count(e) {
    // 0开始 1结束
    if (e) {
      this.setState(preState => ({
        send: false,
        times: 60,
        count: clearInterval(preState.count)
      }));
    } else {
      const count = setInterval(() => {
        const times = this.state.times;
        if (times === 0) {
          this.setState(preState => ({
            send: false,
            times: 60,
            count: clearInterval(preState.count)
          }));
          clearInterval(count);
        } else {
          this.setState(preState => ({
            send: true,
            times: preState.times - 1
          }));
        }
      }, 1000);

      this.setState({
        count: count
      });
    }
  }
  getRealTest(event) {
    this.setState({ test: event.target.value.trim() });
  }
  // 第一步
  async first() {
    const { test } = this.state;
    const { RealPhone, phone } = this.state;
    if (RealPhone === null && phone === "") {
      Toast.info("请获取验证码后注册", 1.5);
      return;
    }
    const phoneReg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
    if (!phoneReg.test(phone)) {
      Toast.info("请输入正确手机号码", 1.5);
      return;
    }
    const reg = /^\d{6}$/;
    if (!reg.test(test)) {
      Toast.info("请输入正确验证码", 1.5);
      return;
    }
    Toast.loading('正在加载...',0);
    await companyUserRegister({
      phone: RealPhone || phone,
      code: test
    }).then((data)=>{
      Cookies.set("token", data.data.token);
      // 结束倒计时
      this.count(1);
      this.setState({
        RealPhone: data.data.phone,
        list: [...data.data.list],
        first: false,
        second: true
      });
      Toast.hide();
    }).catch((res)=>{
      Toast.hide();
      Toast.info(res, 1.5);
    })
  }

  getSelect(event) {
    this.setState({
      customer_nature: event.target.value
    });
  }
  getName(event) {
    this.setState({
      customer_name: event.target.value.trim()
    });
  }
  // 第二步
  async second() {
    const { customer_name, customer_nature, RealPhone, share_id, registerLoading } = this.state;
    if (registerLoading) return
    await this.setState({ registerLoading: true })
    if (customer_name === null || customer_name === "") {
      Toast.info("请输入公司/组织/团体的名称", 1.5);
      await this.setState({ registerLoading: false })
      return;
    }
    if (customer_nature === null) {
      Toast.info("请选择团队性质", 1.5);
      await this.setState({ registerLoading: false })
      return;
    }
    Toast.loading('正在加载...',0);
    await companyCreate({
      customer_nature: customer_nature,
      customer_name: customer_name,
      phone: RealPhone,
      share_id: share_id
    }).then(({data})=>{
      this.setState({
        created: data.customer_name,
        registerLoading: false
      });
      Cookies.remove("token");
      this.setState({
        second: false,
        third: true
      });
      Toast.hide();
    }).catch((res)=>{
      Toast.hide();
      Toast.info(res, 1.5);
      this.setState({
        registerLoading: false
      });
    })
  }

  refresh() {
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    window.location.reload();
  }
  goBack() {
    Cookies.remove("token");
    this.setState({
      first: true,
      second: false,
      phone: "",
      send: false,
      RealPhone: null,
      test: "",
      list: [],
      customer_nature: null,
      customer_name: null
    });
  }
  render() {
    return (
      <div id="register" className={`${style.reg_body}`}>
        <div className={`${style.first} ${this.state.first ? "" : style.hide}`}>
          <div className={`${style.reg_tit}`}>欢迎注册象加</div>
          <div className={`${style.remark}  `}>企业/部门/或其他组织机构/团体注册，如您已注册象加，请在电脑端访问：www.elejoys.com进行登录</div>
          <div className={`${style.phone} `}>
            <input type="text" maxLength="11" value={this.state.phone} onChange={e => this.getPhone(e)} placeholder="请输入注册手机号" />
          </div>
          <div className={`${style.test}`}>
            <div className={`${style.ipt_test}`}>
              <input type="text" maxLength="6" value={this.state.test} onChange={e => this.getRealTest(e)} placeholder="请输入验证码" />
            </div>
            <div onClick={() => this.getTest()} className={`${style.get_test}`}>
              {this.state.send ? this.state.times : "获取验证码"}
            </div>
          </div>
          <div className={`${style.agree}`}>
            继续即视为同意 <span onClick={this.goAgree}> 《象加服务协议》</span>
            <span onClick={this.goSecret}>《象加服务隐私政策》</span>
          </div>
          <div className={`${style.next}`} onClick={() => this.first()}>
            下一步
          </div>
        </div>
        <div className={`${style.second} ${this.state.second ? "" : style.hide}`}>
          <div className={`${style.reg_tit}`}>欢迎注册象加</div>
          <div className={`${style.remark} ${this.state.list.length ? style.hide : ""}`}>嗨，{this.state.RealPhone}，现在创建你的团队吧</div>
          <div className={`${style.remark} ${this.state.list.length ? "" : style.hide}`}>
            嗨，{this.state.RealPhone}，您已经创建了
            {this.state.list.map((item, idx) => (
              <span key={idx}>
                {idx > 2 ? "" : item}
                {idx > 1 ? "" : "、"}
              </span>
            ))}
            {this.state.list.length > 3 ? "等," : ""}
            您还要继续创建团队吗？
          </div>
          <div onClick={() => this.goBack()} className={`${style.go_back}`}>
            返回&nbsp;>
          </div>
          <div className={`${style.cread_company}`}>
            <div className={`${style.tit}`}>创建团队:</div>
            <div className={`${style.ipt}`}>
              <input type="text" onChange={e => this.getName(e)} placeholder="公司/组织/团体名称" />
            </div>
          </div>
          <div className={`${style.company_attr}`}>
            <div className={`${style.tit}`}>团队性质:</div>
            <div className={`${style.select}`}>
              <select defaultValue="0" onChange={e => this.getSelect(e)}>
                <option value="0" disabled>
                  选择性质
                </option>
                <option value="1">企业</option>
                <option value="2">企业部门</option>
                <option value="3">政府组织</option>
                <option value="4">事业单位</option>
                <option value="5">其他组织</option>
              </select>
            </div>
          </div>
          <div onClick={() => this.second()} className={`${style.next}`}>
            立即注册
          </div>
        </div>
        <div className={`${style.third} ${this.state.third ? "" : style.hide}`}>
          <div className={`${style.icon}`} />
          <div className={`${style.success}`}>您已成功注册象加</div>
          <div className={`${style.remark}`}>
            嗨，{this.state.RealPhone}，您已成功在象加注册了<span>{this.state.created}</span>
          </div>
          <div className={`${style.remark}`}>请您移步到电脑端进行登录，开启您的员工激励之旅吧。请在电脑端访问：xj.elejoys.com 进行登录</div>
          <div onClick={() => this.refresh()} className={`${style.next}`}>
            返回首页
          </div>
        </div>
        <div className={` ${style.bottom}`}>
          {/* <div className={` ${style.mo_qcode}`} />
          <div className={` ${style.bot_text}`}>扫码添加公众号</div> */}
          <div className={` ${style.bot_remark}`}>电话热线 / 商业合作:400-160-1160</div>
        </div>
      </div>
    );
  }
}
export default Index;
