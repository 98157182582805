import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import ReactWOW from "react-wow";
import Header from "../../component/header";
import MoHeader from "../../component/header/moHeader";
import Register from "./register";
import Footer from "../../component/footer";
import Active from "../../component/active";
import style from "./index.less";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile, isActive } from "../../store/actions";
import { registerHomeActivity } from "../../request/api";
import Cookies from "js-cookie";

function PcRender() {
  return (
    <div className={`${style.body_wrap}`}>
      <div className={`${style.hide_logo}`}><img src="https://img01.fxqifu.com/98cca26f38143b816b9d11f957b1a759?x-oss-process=image/quality,q_80" alt=""/></div>
      <Active />
      <Header active="index" absolute />
      <div className={`${style.body_banner}`}>
        <div className={`${style.body_img}`}>
          <img src="https://img01.fxqifu.com/3d595b1c8ecec664c19cc5cb525d8196?x-oss-process=image/quality,q_80" alt="" />
          <div className={`${style.middle}`}>
            <div className={`${style.img}`}>
              <img src="https://img01.fxqifu.com/057f7593a7a18516dab30afa8bc9b58a?x-oss-process=image/quality,q_80" alt="" />
            </div>

            <div className={`${style.title}`}>一个社交化的员工激励平台</div>
            <div className={`${style.body_qcode}`}>
              立即体验
              <div className={`${style.hover_qcode}`} />
            </div>
          </div>
          <div className={`${style.body_text}`}>
            <div className={`${style.tit}`} />
            <div className={`${style.remark}`} />
          </div>
        </div>
      </div>
      <div className={`${style.body_content}`}>
        <div className={`${style.ad_text}`}>
          <div className={`${style.tit}`}>智慧员工福利解决方案，放大福利价值</div>
          <div className={`${style.remark}`}>无需人工干预，系统自动执行，超省事</div>
        </div>
        <div className={`${style.ad_img}`}>
          <img src="https://img01.fxqifu.com/2eaf4f89d5bcd812d506605720ef07db?x-oss-process=image/quality,q_80" alt="" />
        </div>
        <div className={`${style.ad_text}`}>
          <div className={`${style.tit}`}>积分商城，给员工更多选择</div>
          <div className={`${style.remark}`}>品牌精选，让生活更上档次</div>
        </div>
        <div className={`${style.ad_img}`}>
          <img src="https://img01.fxqifu.com/e38ead299777789c5f2528285d3580ee?x-oss-process=image/quality,q_80" alt="" />
        </div>
        <div className={`${style.ad_text}`}>
          <div className={`${style.tit}`}>让工作更有成就，让生活更有温度</div>
          <div className={`${style.remark}`}>员工赞赏 ，开启员工激励新时代</div>
        </div>
        <div className={`${style.ad_img}`}>
          <img src="https://img01.fxqifu.com/964b47c9c3ce330ef8592e8f92dd16d9?x-oss-process=image/quality,q_80" alt="" />
        </div>
        <div className={`${style.ad_friend}`}>
          <div className={`${style.friend}`}>
            <div className={`${style.tit}`}>
              <i className={`${style.icon}`} />
              <span>商务合作伙伴</span>
            </div>
            <div className={`${style.tit}`}>
              <i className={`${style.icon}`} />
              <span>合作客户</span>
            </div>
          </div>
          <div className={`${style.img}`}>
            <img src="https://img01.fxqifu.com/539828cd4e06ca1ddd6d164983e9c8f2?x-oss-process=image/quality,q_80" alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
function MobileRender(props) {
  return (
    <div>
      <Active />
      <MoHeader />
      <div className={`${style.mo_banner}`}>
        <div className={`${style.mo_english}`}>Better work，better life</div>
        <div className={`${style.mo_chinese}`}>一个社交化的员工激励平台</div>
        <div id="btn" onClick={props.onClick} className={`${style.mo_btn}`} />
        <div className={`${style.pc_tips} ${props.active.name ? "" : style.hide}`}>{props.active.name}</div>
      </div>
      <div className={`${style.mo_text}`}>
        <div className={`${style.tit}`}>智慧员工福利解决方案，放大福利价值</div>
        <div className={`${style.remark}`}>无需人工干预，系统自动执行，超省事</div>
      </div>
      <div className={`${style.mo_ad}`} />
      <div className={`${style.mo_ad}`} />
      <div className={`${style.mo_text}`}>
        <div className={`${style.tit}`}>积分商城，给员工更多选择</div>
        <div className={`${style.remark}`}>品牌精选，让生活更上档次</div>
      </div>
      <div className={`${style.mo_band}`} />
      <div className={`${style.mo_text}`}>
        <div className={`${style.tit}`}>让工作更有成就，让生活更有温度</div>
        <div className={`${style.remark}`}>员工赞赏 ，开启员工激励新时代</div>
      </div>
      <div className={`${style.mo_works}`} />
      <div className={`${style.mo_with}`} />
      <div className={`${style.mo_text} ${style.mo_bg}`}>
        <div className={`${style.tit}`}>商务合作伙伴</div>
        <div className={`${style.remark}`} />
      </div>
      <div className={`${style.mo_bands}`} />
      <div className={`${style.mo_text} ${style.mo_bg}`}>
        <div className={`${style.tit}`}>合作客户</div>
        <div className={`${style.remark}`} />
      </div>
      <div className={`${style.mo_plays}`} />
      <Register />
      <div onClick={props.onClick} className={`${style.must} ${props.show ? style.show : ""}`}>
        立即注册
      </div>
    </div>
  );
}
@withRouter
class Index extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      btnTotop: 0,
      registerTotop: 0,
      goRegister: 0,
      show: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  async componentWillMount() {
    // 判断移动端
    if ("ontouchstart" in window) {
      this.props.isMobile(true);
      // 监听滚动条
      window.addEventListener("scroll", this.handleScroll);
      setTimeout(() => {
        // 移动端-立即体验按钮距离顶端的高度
        const btnTotop = document.getElementById("btn").offsetTop + document.getElementById("btn").offsetHeight;
        // 移动端-注册区域距离顶端的高度
        const registerTotop = document.getElementById("register").offsetTop - window.screen.availHeight;
        const goRegister = document.getElementById("register").offsetTop;
        this.setState({
          btnTotop: btnTotop,
          registerTotop: registerTotop,
          goRegister: goRegister
        });
      }, 10);
    }
  }
  async componentDidMount() {
    // 活动是否开启
    const { data: data } = await registerHomeActivity();
    // 启用状态
    if (data.state == "1") {
      if (Cookies.get("active-time")) {
        const prevTime = Cookies.get("active-time");
        const nowTime = new Date().getTime();
        // 判断上次展示活动弹窗时间间隔是否是8小时
        if (nowTime - prevTime > 28800000) {
          this.props.isActive({ ...data, ...{ close: false } });
          Cookies.set("active-time", nowTime);
        }
      } else {
        const nowTime = new Date().getTime();
        this.props.isActive({ ...data, ...{ close: false } });
        Cookies.set("active-time", nowTime);
      }
      // tips只要开启 一直展示
      this.props.isActive({ name: data.name });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.setState = (state, callback) => {
      return;
    };
  }
  handleScroll() {
    // 滚动条距离顶部的高度
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const { btnTotop = 0, registerTotop = 0 } = this.state;
    if (scrollTop > btnTotop || scrollTop < registerTotop) {
      this.setState({
        show: true
      });
    }
    if (scrollTop <= btnTotop || scrollTop >= registerTotop) {
      this.setState({
        show: false
      });
    }
  }
  goRegister() {
    window.scrollTo({
      top: this.state.goRegister,
      behavior: "smooth"
    });
  }
  render() {
    if (this.props.isMobileFlag) {
      return <MobileRender active={this.props.activeCont} show={this.state.show} onClick={() => this.goRegister()} />;
    } else {
      return (
        <div>
          <PcRender />
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    isMobileFlag: state.judgeDevice,
    activeCont: state.judgeActive
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ isMobile, isActive }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
