exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3fWb1WdSf3r4s4T890tiut {\n  position: absolute;\n}\n", ""]);

// exports
exports.locals = {
	"text": "_3fWb1WdSf3r4s4T890tiut"
};